// 作品详情
<template>
    <div class="view-detail" :class="type === '1' ? 'cities' : ''">
        <div class="detail-content">
            <div class="video-content">
                <div class="works-item">
                    <div class="item-image-box">
                        <div class="prism-player" id="J_prismPlayer"></div>
                    </div>
                    <div class="item-info-box">
                        <div class="item-title">{{ data.Name }}</div>
                        <div class="item-info-other">
                            <div class="item-author">
                                <img src="../static/image/icon/icon_hy.png"/>
                                <span>{{data.UserName}}</span>
                            </div>
                            <div class="item-agree">
                                <img src="../static/image/icon/icon_70_ydz.png"/>
                                <span>{{data.VoteNum}}</span>
                            </div>
                            <!--                            <div class="item-share">-->
                            <!--                                <img src="../static/image/icon/icon_70_fx.png"/>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="warning-box">
                <img src="../static/image/icon/icon_yh.png"/>
                <span>人气点赞（8月17日——8月23日）：平台开放点赞功能，进入终评的参赛者可通过微信转发集赞，将根据点赞数量产生10名“网络人气奖”。</span>
            </div>
            <div class="bottom-btn" v-if="data.AuditStatus == '3'">
                <div class="agree-btn" @click="setAgree">{{ agreeBtnTxt }}</div>
            </div>
        </div>
        <Back></Back>
    </div>
</template>

<script>
    import Vue from 'vue'
    import getStrParams from "../utils/getStrParams";
    import {Toast} from 'vant'
    import {worksInfo, voteOne} from '../server'
    import core from '../core/index'
    import Back from "../components/Back";
    import initWXAPI from "../utils/initWXAPI";
    import WXShare from "../utils/WXShare";

    Vue.use(Toast)

    export default {
        name: "Detail",
        components: {Back},
        data() {
            return {
                loading: false,
                agreeBtnTxt: '为TA点赞',
                isAgree: false,
                type: '1',
                data: {
                    type: '1',
                    id: '',
                    Name: "",
                    UserName: '',
                    VoteNum: '',
                    image: require('../static/image/ny_bg.png')
                },
            }
        },
        methods: {
            async getDetail() {
                const data = await worksInfo({
                    SessionId: this.sessionId,
                    GiId: this.id
                })
                const _data = data.Data;
                if (data.ErrorMsg) {
                    Toast(data.ErrorMsg)
                    return;
                }
                this.data = _data
                this.initVideoPlay()
                this.initPageShare()
            },
            initVideoPlay() {
                let ymkd = document.body.clientWidth;
                let ymgd = ymkd * 9 / 16;
                const player = new Aliplayer({
                    id: "J_prismPlayer",
                    autoplay: false,
                    source: this.data.PlayURL, // 视频地址
                    cover: this.data.CoverURL, //封面地址
                    width: "100%",
                    height: "100%"
                });
            },
            async setAgree() {
                if (this.loading || this.isAgree) return;
                this.loading = true
                this.agreeBtnTxt = '点赞中...'
                const data = await voteOne({
                    SessionId: this.sessionId,
                    Id: this.id
                })
                this.loading = false;
                if (data.ErrorMsg) {
                    Toast({message: data.ErrorMsg, position: 'bottom'});
                    return
                }
                if(data.Data&& data.Data.Id === 0) {
                    Toast({message: data.Data.Msg, position: 'bottom'});
                } else {
                    Toast({message: '点赞成功', position: 'bottom'});
                }
                this.agreeBtnTxt = '已点赞'
                this.isAgree = true
                this.data.VoteNum++;
            },
            initPageShare() {
                // window.location.href.split(window.location.host)[1]
                initWXAPI.init(this.$store.state.url)
                const options = {
                    title: '红色故事演讲大赛',
                    desc: '我在红色故事大赛上传了作品，快来帮我点赞！',
                    imgUrl: this.data.CoverURL,
                    link: window.location.href
                    // link: window.location.href.split(window.location.host)[1]
                }
                WXShare.initWXShare(options)
                WXShare.initWXTimelineShare(options)
            }
        },
        created() {
            this.sessionId = core.cookie.getStorage('sessionId')
            this.id = this.$route.params.id
            this.getDetail()
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/common";

    .view-detail {
        background: url("../static/image/ny_bg.png") no-repeat top left #be0b15;
        background-size: contain;
        padding-top: toRem(400);
        min-height: 100vh;
        box-sizing: border-box;
        &.cities{
            background-image: url("../static/image/ny_bg.png");
        }
        .detail-content {
            .video-content {
                .works-item {
                    margin: 0 toRem(20) toRem(20);

                    .item-image-box {
                        z-index: 99;
                    }

                    .item-info-box {
                        padding: toRem(20);
                        background: #2b0305;

                        .item-title {
                            color: #fff;
                        }

                        .item-info-other {
                            display: flex;
                            color: #bbb;

                            img {
                                width: toRem(32);
                                margin-right: toRem(12);
                            }

                            span {
                                font-size: toRem(22);
                            }

                            img, span {
                                vertical-align: middle;
                            }

                            .item-author {
                                flex: 1;
                            }

                            .item-agree {
                                width: toRem(120);
                            }

                            .item-share {
                                width: toRem(40);
                            }
                        }
                    }
                }

            }

            .warning-box {
                padding: 0 toRem(20);

                img, span {
                    vertical-align: bottom;
                }

                img {
                    width: toRem(50);
                }

                span {
                    font-size: toRem(24);
                    color: rgba(255, 250, 140, 0.75);
                }
            }

            .bottom-btn {
                padding-bottom: toRem(30);
                font-size: toRem(36);
                margin-top: toRem(100);

                .agree-btn {
                    display: inline-block;
                    width: 100%;
                    height: toRem(120);
                    text-align: center;
                    line-height: toRem(130);
                    background: url("../static/image/btn_ju.png") no-repeat top left;
                    background-size: contain;
                }

            }
        }
    }
</style>